import React, { createContext, useContext, useEffect, useState } from "react";

const LocalStorageContext = createContext();

export const LocalStorageProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem("token") || null);
    const [expoPushToken, setExpoPushToken] = useState(localStorage.getItem("expoPushToken") || null);
    const noticeKey = "noticeRead-0528";
    const [noticeRead, setNoticeRead] = useState(localStorage.getItem(noticeKey) || null);

    const updateToken = (newToken) => {
        localStorage.setItem("token", newToken);
        setToken(newToken);
    };

    const removeToken = () => {
        localStorage.removeItem("token");
        setToken("");
    };

    const readNotice = () => {
        localStorage.setItem(noticeKey, "true");
        setNoticeRead(true);
    };

    /**
     * 로컬스토리지 변경을 감지하는 이벤트 리스너 설정
     * 앱에서 로컬스토리지에 expoPushToken을 저장하면 감지하여 state 업데이트
     */
    useEffect(() => {
        const handleStorageChange = () => {
            setExpoPushToken(localStorage.getItem("expoPushToken") || null);
            // setNoticeRead(localStorage.getItem(noticeKey) || null);
        };

        // 리액트네이티브에서 postMessage로 전달받은 이벤트 처리 -> 알림 권한 꺼져있다 켜지는 경우 이벤트 전달
        const handleMessageEvent = (event) => {
            try {
                const { data, type } = JSON.parse(event.data);
                if (type === "EXPO_PUSH_TOKEN") {
                    setExpoPushToken(data);
                    localStorage.setItem("expoPushToken", data);
                }
            } catch (error) {
                // console.error("Failed to parse event.data", error);
            }
        };

        window.addEventListener("storage", handleStorageChange);
        window.addEventListener("message", handleMessageEvent, true);
        document.addEventListener("message", handleMessageEvent);

        // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
        return () => {
            window.removeEventListener("storage", handleStorageChange);
            window.removeEventListener("message", handleMessageEvent);
            document.removeEventListener("message", handleMessageEvent);
        };
    }, []);

    return (
        <LocalStorageContext.Provider value={{ token, updateToken, removeToken, expoPushToken, noticeRead, readNotice }}>
            {children}
        </LocalStorageContext.Provider>
    );
};

export const useLocalStorage = () => useContext(LocalStorageContext);
