import React, { useEffect } from "react";
import { Box, Card, CardContent, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import { getIcon } from "../utils/iconUtils";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { convertTo12HourFormat } from "../utils/dateUtils";
import { useUser } from "../context/UserContext";
import { services } from "../data/services";
import ServiceReview from "../components/ServiceReview";
import ServiceModals from "../components/servicemodal/ServiceModals";
import ToDoList from "../components/todolist/ToDoList";

function Home() {
    const navigate = useNavigate();
    const { expoPushToken, token, user, isLoading } = useUser();
    const [selectedServiceType, setSelectedServiceType] = React.useState("");

    // 로컬 스토리지에 토큰을 확인하여, 로그인 안 된 상태면 인트로 페이지로 넘긴다.
    useEffect(() => {
        if (isLoading) return; // 로딩 중이면 아래 로직 수행하지 않음
        if (!user) {
            navigate("/intro");
        } else if (user?.notificationTimes.length === 0 || user?.services.length === 0) {
            navigate("/settings");
        }
    }, [navigate, user, isLoading]); // isLoading 의존성 추가

    const notUsedServices = () => {
        return services.filter((service) => !user.services.some((userService) => userService.type === service.type));
    };

    return user && user.services?.length > 0 && user.notificationTimes?.length > 0 ? (
        <Box sx={{ width: "95vw" }}>
            <Card variant="contained" sx={{ borderRadius: "12px", marginBottom: "18px" }}>
                <CardContent sx={{ margin: "10px", padding: "16px", "&:last-child": { paddingBottom: "0px" } }}>
                    <Typography gutterBottom sx={{ fontFamily: "Orbit-Regular", fontWeight: "bold", fontSize: "4.8vw" }}>
                        {user.nickname ? `${user.nickname}님이` : ""} 이용중인 서비스 목록
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: "3.3vw" }}>
                        (터치해서 실시간 정보를 확인할 수 있어요!)
                    </Typography>
                    <List>
                        {user.services.map((service, index) => (
                            <ListItemButton key={index} onClick={() => setSelectedServiceType(service.type)}>
                                <ListItemIcon>{getIcon(service.type)}</ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography sx={{ fontFamily: "SUITE-Regular", fontSize: "4.5vw" }}>
                                            {services.find((it) => it.type === service.type).name}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        ))}
                    </List>
                </CardContent>
            </Card>
            {user.id === 1 && (
                <Card variant="contained" sx={{ pt: "26px", pb: "8px", borderRadius: "12px", marginBottom: "18px" }}>
                    <ToDoList />
                </Card>
            )}
            {notUsedServices().length > 0 && (
                <Card variant="contained" sx={{ borderRadius: "12px", marginBottom: "18px" }}>
                    <CardContent sx={{ margin: "10px", padding: "10px", "&:last-child": { paddingBottom: "0px" } }}>
                        <Typography gutterBottom sx={{ fontFamily: "Orbit-Regular", fontWeight: "bold", fontSize: "4.8vw" }}>
                            추가로 이용 가능한 서비스 목록
                        </Typography>
                        <List>
                            {notUsedServices().map((service, index) => (
                                <ListItemButton key={index} onClick={() => navigate("/settings")}>
                                    <ListItemIcon>{getIcon(service.type)}</ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ fontFamily: "SUITE-Regular", fontSize: "4.5vw" }}>{service.name}</Typography>} />
                                </ListItemButton>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            )}
            <Card variant="contained" onClick={() => navigate("/settings")} sx={{ borderRadius: "12px" }}>
                <CardContent sx={{ margin: "10px", padding: "10px", "&:last-child": { paddingBottom: "10px" } }}>
                    <Typography gutterBottom sx={{ fontFamily: "Orbit-Regular", fontWeight: "bold", fontSize: "4.8vw" }}>
                        {convertTo12HourFormat(user?.notificationTimes[0])}에 알림 발송될 예정입니다.
                    </Typography>
                    {!user.expoPushToken && (
                        <Typography gutterBottom sx={{ fontSize: "3.3vw" }}>
                            (<span style={{ color: "red", fontWeight: "bold" }}>알림 권한이 없어</span> 알림을 받을 수 없습니다.)
                        </Typography>
                    )}
                </CardContent>
            </Card>
            <ServiceReview />
            <ServiceModals serviceType={selectedServiceType} setServiceType={setSelectedServiceType} token={token} />
        </Box>
    ) : (
        <Loading />
    );
}

export default Home;
